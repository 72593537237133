<template>
    <div class="flex flex-col justify-end" :class="[ checkRootNameAndBanners() ? 'footer' : '']">
        <div class="wrapper w-full text-xs  pb-12 pt-5 mb-10 h-32">
            <div class="flex justify-center">
                <div :class="$root.hotel.site == 'moon2.2roomz.com' ? 'text-white' : ''">{{ getCoName() }} &copy;{{ new Date().getFullYear() }}</div>
                <a href="javascript:void(0)" @click="openPrivacyPolicy" class="ml-2 underline">{{ $root.getKeyWord('privacy_policy') }}</a>
            </div>
            <div v-if="$root.hotelCode == 'azimuthotelspereslavl'" class="flex justify-center">
                <div class="text-xs cursor-pointer text-blue-500 underline"
                  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')">
                    <span v-if="this.$root.currentLanguage == 'ru'">Публичная оферта</span>
                    <span v-else>Public offer</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
    name: 'AppFooter',
    methods: {
        checkRootNameAndBanners() {
            return this.$route.name === 'home' && this.$root.hotel && this.$root.hotel.banners && this.$root.hotel.banners.length > 0;
        },

        openPrivacyPolicy() {
            this.$modal.show('modal-privacy-policy');
            this.$root.getPublicInformation('privacy_policy');
        },
    }
}
</script>
