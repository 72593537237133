<template>
    <a-drawer :wrapClassName="isDesktopVersion() ? 'drawer-custom-desktop' : 'drawer-custom'" :placement="isDesktopVersion() ? 'right' : 'bottom'"  :width="isDesktopVersion() ? 440 : 'auto'"  :height="'bottom'"  :closable="false" @close="onClose" :visible="$parent.visibleBottomServices">
        <div class="flex justify-end">
            <a href="javascript:void(0)" @click="$parent.visibleBottomServices = false" class="block hover:bg-gray-100 rounded-full p-1">
                <svg xmlns='http://www.w3.org/2000/svg' class='fill-current text-gray-800 rounded-full' viewBox='0 0 512 512'  height="32" width="32"><title>Close</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M368 368L144 144M368 144L144 368'/></svg>
            </a>
        </div>
        <div class="flex flex-col overflow-y-scroll" style="max-height: 336px !important;">
            <a v-for="section in sections" 
                v-show="!section.is_hidden"
                href="javascript:void(0)" @click="clickSection(section)"  
                aria-current="page" 
                class="py-4 px-4 text-sm border-b base-color"                
                >
                {{ $root.getLangText(section.title) }}
            </a>
        </div>
    </a-drawer>    
</template>

<script>
export default {
    name: 'AppBottomServices',

    data() {
        return {
            sections: null,
        }
    },

    methods: {
        onClose() {
            this.$parent.visibleBottomServices = false;
        },

        clickSection(item) {
            this.$root.yandexGoal('goal - category - ' + item.id);

            if (item.external_link) {
                window.open(item.external_link, '_blank');
                return false;
            } else {
                //this.$root.title = item.title;
                //this.$router.push({path: '/products/' + item.id});
                if (item.sublevels_count > 1) {
                    window.location.href = '/sections/' + item.id;    
                    return;
                }
                window.location.href = '/products/' + item.id;
            }
        },

        fetchSections() {
            this.$http.get(process.env.VUE_APP_API_URL + 'public/sections?hotel_uuid=' + this.$root.hotel.uuid)
                .then(response => {
                    this.sections = response.data.data;
                });
        },
    }    
}
</script>