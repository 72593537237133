export const IsAnyProductGotRoomNumberOptionMixin = {
    methods: {
        isAnyProductGotRoomNumberOption(products) {
            var except_sections = this.$root.hotel.ext_data.properties.room_number.except_sections;

            if (!except_sections) {
                return true;
            }

            return products.filter(item => {
                const sectionId = String(item.section_id ?? item.section?.id);
                const parentSectionId = String(item.parent_section?.id);
                const subsectionIds = item.parent_section?.subsections?.map(sub => String(sub.section_id)) || [];

                // Skip if parent section is excluded
                if (except_sections.includes(parentSectionId)) {
                    return false;
                }

                // Skip if current section is excluded
                if (except_sections.includes(sectionId)) {
                    return false;
                }     
                
                // Skip if any subsection is excluded
                if (subsectionIds.filter(id => except_sections.includes(id)).length > 0)
                    return false;

                return true;
            }).length > 0;
        },

        roomNumberInputTitle() {
            var roomNumberTitle = this.$root.hotel.ext_data.properties.room_number.title;

            return roomNumberTitle
                ? this.$root.getLangText(roomNumberTitle)
                : this.$root.getKeyWord('room_number');
        }
    },
};

