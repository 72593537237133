<template>

    <modal name="modal-agreement" :width="'100%'" :height="'100%'" scrollable @before-open="handleBeforeOpen">
        <div class="flex py-4 px-4 shadow text items-center text-black">
            <button @click="$modal.hide('modal-agreement')"><svg xmlns='http://www.w3.org/2000/svg' class='w-8 h-8 fill-current' viewBox='0 0 512 512'><title>Arrow Back</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M244 400L100 256l144-144M120 256h292'/></svg></button>
            <div class="ml-2">{{ title ? title : $root.getKeyWord('staff_data') }}</div>
        </div>
        <div class="p-4 text-xs h-screen overflow-y-auto text-black">
            <div v-html="content ? $root.getLangObject(content) : $root.getLangObject($root.public_info.full_agreement)" style="margin-bottom: 100px;"></div>
        </div>    
    </modal>

</template>
<script>
  export default {
    name: 'app-agreement',

    data() {
      return {
        content: null,
        title: null,
      }
    },

    methods: {
      handleBeforeOpen(e) {
        this.title = null;
        this.content = null;
        
        this.title = e.params.title;
        this.content = e.params.content;
      }
    }
  }
</script>


