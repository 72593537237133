<template>
<a-drawer wrapClassName="drawer-product"  placement="bottom" :height="'bottom'"  :closable="false" @close="close" :visible="visible">
  <div class="bg-gray-100 relative flex no-wrap flex-nowrap flex-col" id="wrapper-product-cart" style="max-height: 400px;" v-if="product">
    <div style="flex: 0 1 auto; overflow-x: hidden; overflow-y: auto; min-height: 64px;" @scroll="handleScrollInsidePopup" class="overflow-y w-full in-product relative">
      <div class="relative" style="padding-bottom: 66.6%" v-if="product.image">
          <img class="absolute h-full w-full" v-lazy="getLasyImage($root.getImage(product.image))" alt="" >
      </div>
      <div v-if="$root.hotelCode == 'sofitelwarsawvictoria' && parent_section.id == 6610">
        <div class="px-4 pt-4" style="line-height: 18px; color: #736d6d; width: 95%" v-if="$root.getLangText(product.descr)" v-html="$root.getLangText(product.descr)"></div>
      </div>
      <div class="px-4 py-4" v-if="productHasProperties">   
        <app-properties-new ref="property" 
          :property.sync="product.property" 
          :product-id="product.id" 
          :product-uuid="product.uuid" 
          :max-booking-slot="product.max_booking_slot"
          :quantity="quantity" 
          :schedule="product.schedule" 
          :parent-section-ids="[parent_section.id]" 
          :errors="errors">
        </app-properties-new>
      </div>
      <button v-if="productHasProperties && visible" class="scrollToTopBtn rounded-full bg-white shadow p-2 md:hidden" @click="scrollToTop">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-black">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
      </button>
        <app-recommended-products-for-products-modal :key="recommendations_key" v-if="$root.hotel.location_of_recommendations === 'everywhere'" :products="[product]"></app-recommended-products-for-products-modal>

    </div>

    <div class="flex absolute top-2 gap-2 close-product">
      <a href="javascript:void(0)" class="w-10 h-10 text-black bg-white focus:outline-none rounded-full  text-center inline-flex justify-center items-center shadow-md" v-if="action.type == 'update'" @click="remove">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="fill-current text-black" viewBox="0 0 512 512"><title>Trash</title><path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 112h352"/><path d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>        
      </a>
      <a href="javascript:void(0)" class="w-10 h-10 text-black bg-white focus:outline-none rounded-full  text-center inline-flex items-center shadow-md" @click="close">
        <svg width="48" height="48" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.2427 12.7574L12.7574 21.2426M12.7574 12.7574L21.2427 21.2426L12.7574 12.7574Z" stroke="#333333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a> 
    </div>  

    <div class="px-4 py-2  border-t w-full" :class="[$root.hotelCode == 'taigara' ? 'bg-[#455E4C] text-white' : 'bg-white']" style="flex: 0 0 auto;" id="footer-product-cart">
      <div class="flex items-start gap-2 justify-between mb-4 pb-4 pt-2">
        <div class="flex-1 leading-tight" :class="[$root.hotelCode == 'taigara' ? 'name_product_taigara' : 'name_product']" v-text="$root.getLangText(product.title)"></div>
        <div class="price_product leading-tight" v-html="$root.showPrice(price)"></div>
      </div>
      <div class="flex items-center justify-between">
        <div class="w-1/2">
          <div class="relative flex" v-if="product.show_quantity">
            <v-input-quantity :value.sync="quantity" :min="1" :max="product.max_order_quantity == null ? 99 : parseInt(product.max_order_quantity)"></v-input-quantity>
            <div v-if="isSlotTimeProduct" class="leading-none flex items-center pl-[10px]">
                {{$root.currentLanguage == 'ru' ? 'Сколько человек записать?': 'How many people should I record?'}}
            </div>
          </div>
        </div>
        <div class="w-1/2 flex justify-end" v-if="isActiveOrderButton">
          <div>
            <button class="green_add_to_cart w-full ml-auto uppercase" :class="[valid !== null && !valid ? 'shake-btn': '']" :style="'background-color: ' + $root.hotelColor() + ''" @click="store()" v-text="buttonOrderText"></button>
          </div>
        </div>
      </div>
    </div>        
  </div>
</a-drawer>
</template>

<script>
import { eventBus } from '../../main';

import AppPropertiesNew from '@/components/article/Properties/index';
import AppRecommendedProductsForProductsModal from "@/components/article/AppRecommendeProductsForProductsModal.vue";

class Errors {
  constructor() {
    this.errors = {};
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  set(field) {
    this.errors[field].push({});
  }

  searchByKey(field) {
    return Object.keys(this.errors).filter(item => item.indexOf(field) > -1).length > 0;
  }

  record(errors) {
    this.errors = errors;
  }
}

export default {
  name: 'app_product_cart',
  
  components: {AppRecommendedProductsForProductsModal, AppPropertiesNew },

  data() {
    return {      
      product: null,
      section: null,
      parent_section: null,
      main_section: null,
      from_recommendation: null,
      errors: new Errors(),
      action: {
        type: 'create',
        index_for_update: null,
      },

      price: 0,
      quantity: 1,
      valid: null,
      visible: false,
        recommendations_key: 0,
    }
  },

  created() {
      eventBus.$on( 'product-cart', data => {
          this.product = JSON.parse(data.product);
          this.section = data.section;
          this.parent_section = data.parent_section;
          this.main_section = data.main_section;
          this.from_recommendation = data.from_recommendation;
          this.action = data.action;
          this.price = parseFloat(data.product.price);
          this.quantity = data.quantity ? data.quantity : 1;
          this.valid = null;
          this.visible = true;

          this.$nextTick(() => {
            var docHeight = document.documentElement.clientHeight;
            document.querySelector('#wrapper-product-cart').style.maxHeight =  Math.round(docHeight - (docHeight * 0.1)) + 'px';

            eventBus.$emit('update-slot-calendar');
          });
      });      
  },

  watch: {
      quantity(val) {
          if (val == undefined) return;

          this.setTotalPrice();
      },

      product: {
          handler: function(newValue) {
              this.setTotalPrice();              
          },
          deep: true
      },

      visible(val) {
        this.$root.freezeActivePageScroll(val);
          if (val) {
              this.recommendations_key++;
          }
      }
  },

  computed: {
      isActiveOrderButton(){
          return (this.section !== undefined && !this.section.hide_order_button);
      },

      isSlotTimeProduct(){
        return false;
          /*return (this.s_main_properties.find(function(property){
              return property.ptype_id == 'slottime';
          }));*/
      },

      buttonOrderText() {
        let btnOrdTextProduct = this.$root.getLangText(this.product.button_order_text);
        
        if (btnOrdTextProduct)
          return btnOrdTextProduct;
          

        return this.$root.getKeyWord('send_to_cart');
      },

      productHasProperties() {
        return this.product.property_kit_id > 0 && Object.keys(this.product.property).length > 0 && this.product.property.properties.length
      }
  },


  methods: {
    close() {
      this.visible = false;
    },
    
    store() {
      // check validation properties
      if (this.productHasProperties) {
        this.valid = this.$refs.property.validate();
        if (this.valid !== null && !this.valid) {
          return false;
        }
      }

      var cart = localStorage['cart'] !== undefined 
          ? JSON.parse(localStorage['cart']) 
          : {
              room_number: '',
              payment: '',
              comment: '',
              hotel_id: this.$root.hotel.id,
              property: this.$root.hotel.property,
              uuid: '',
              is_temporary: false,
              products:[]
          }

          console.log('parent_section', this.parent_section);
                   
          var obj = {
            product: {
              id: this.product.id,
              title: this.product.title,
              descr: this.product.descr,
              price: this.product.price,
              uuid: this.product.uuid,
              image: this.product.image,
              image_path: this.product.image_path,
              section_id: this.product.section_id,
              property: this.product.property,
              schedule: this.product.schedule,
              show_quantity: this.product.show_quantity,
              max_order_quantity: this.product.max_order_quantity,
              property_kit_id: this.product.property_kit_id,
              button_order_text: this.product.button_order_text,
              free_delivery: this.product.free_delivery
            },
            quantity: this.quantity,
            total_price: this.price,
            from_recommendation: this.from_recommendation,
            section: {
              id: this.section.id,
              title: this.section.title,
              text_accepted_order: this.section.text_accepted_order,
              split_order: this.section.split_order,
              hide_back_button: this.section.hide_back_button,
              hide_order_button: this.section.hide_order_button,
              hide_view_orders_button: this.section.hide_view_orders_button,
              free_delivery: this.section.free_delivery           
            },
            parent_section: {
              id: this.parent_section.id,
              title: this.parent_section.title,
              text_accepted_order: this.parent_section.text_accepted_order,
              split_order: this.parent_section.split_order,
              hide_back_button: this.parent_section.hide_back_button,
              hide_order_button: this.parent_section.hide_order_button,
              hide_view_orders_button: this.parent_section.hide_view_orders_button,
              description: this.parent_section.descr,
              cart_text: this.parent_section.cart_text,
              free_delivery: this.section.free_delivery,
              is_hidden: this.parent_section.is_hidden,
              subsections: this.parent_section.subsections ? this.parent_section.subsections : null,
            },
            main_section: {
              id: this.main_section ? this.main_section.id : this.parent_section.id,
              split_order: this.main_section ? this.main_section.split_order : this.parent_section.split_order,
            }
          };

            if (this.action.type == 'update') {
              cart['products'][this.action.index_for_update] = obj;
            } else {
              cart['products'].push(obj);
            }

            console.log(obj);
      localStorage['cart'] = JSON.stringify(cart);
      this.$root.updateCart();
      this.visible = false;

      this.$root.yandexGoal('goal - cart - finish');
    },

    remove() {
      var cart =  JSON.parse(localStorage['cart']);
      var products = cart['products'];
      if (this.action.type == 'update') {
        products.splice(this.action.index_for_update, 1);
        cart['products'] = products;
        localStorage['cart'] = JSON.stringify(cart);
        this.$root.updateCart();
        if (products.length === 0) {
            eventBus.$emit('is-empty-cart');
        }
        this.visible = false;
      }
    },

    getParentSection() {
      return (this.$parent.sections && this.$parent.sections[this.product.section_id] !== undefined) 
          ? this.$parent.sections[this.product.section_id] 
          : false;
    },

    setTotalPrice() {
        let total = 0;
        let product_price = parseFloat(this.product.price);
        let property_total_price = 0;

        // check if product has selected properties
        if (this.product.property_kit_id > 0 && this.product.property.id !== undefined) {
          this.product
            .property
            .properties
            .filter(item => item.value !== '' && item.value !== null && item.price_type_id !== 'none')
            .map(item => {
              if (item.price_type_id === 'replace')
                product_price = 0;

              if (item.ptype_id == 'select') {
                property_total_price += item.attrs
                  .filter(item_attr => item.value === item_attr.id && item_attr.price !== null)
                  .reduce(function (acc, item_attr) { return acc + parseFloat(item_attr.price) * item_attr.quantity; }, 0);
              }
              if (item.ptype_id == 'datetime' && item.pcode_id == 'scheduled') {
                property_total_price += product_price * item.value.length;
              } else if (item.ptype_id == 'checkbox') {
                var attr_price = item.attrs
                  .filter(item_attr => item_attr.checked && item_attr.price !== null)
                  .reduce(function (acc, item_attr) { return acc + parseFloat(item_attr.price) * item_attr.quantity; }, 0);
                
                attr_price = attr_price === 0 && item.price > 0 ? item.price : attr_price;

                property_total_price += attr_price;
              } else if (item.price > 0) {
                property_total_price += item.price;
              }
          });
        }

        if(this.isScheduledProduct(this.product)){
            product_price = 0;
        }
        this.price = this.quantity * (product_price + parseFloat(property_total_price));
        this.$root.yandexGoal('goal - cart - finish');
    },

    handleScrollInsidePopup() {
      var scrollToTopBtn = document.querySelector(".scrollToTopBtn");
      var rootElement = document.querySelector(".in-product");

      if (!scrollToTopBtn)
        return;

      scrollToTopBtn.style.bottom = (document.getElementById('footer-product-cart').clientHeight - 15) + 'px';
      if (rootElement.scrollTop > 100) {
        scrollToTopBtn.classList.add("showBtn");
      } else {
        scrollToTopBtn.classList.remove("showBtn");
      }
    },

    scrollToTop() {
      var rootElement = document.querySelector(".in-product");
      rootElement.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }    

  }
}
</script>

<style>

.scrollToTopBtn {
  border: none;
  color: white;
  cursor: pointer;
  position: fixed;
  z-index: 100;
  opacity: 0;
  transition: all 0.5s ease;
  left: 50%;
  transform: translateX(-50%);
}

.showBtn {
  opacity: 1;
}
</style>