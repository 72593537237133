<template>
    <div class="flex my-1 gap-2 flex-col items-center" :style="cssProps">
        <div class="timeslot-calendar">
            <date-picker class="w-full border" v-model="selected_date" :default-value="new Date()" @calendar-change="handleCalendarChange" :disabled-date="disabledDays" :lang="lang"  valueType="format" :format="'YYYY-MM-DD'"  :inline="true"></date-picker>
        </div>  
        
        <!--<div class="flex justify-center"><loading v-if="loading_time"></loading></div>-->
        <div class="grid grid-cols-3 gap-x-3 gap-y-2 slot-times" :class="[avaiable_times && avaiable_times.length ? 'max' : '']">            
            <button v-for="item in avaiable_times" 
                    :class="[ isSelectedTime(item.start_time) ? 'active-time text-white' : 'bg-white text-gray-800', !isSelectedTime(item.start_time) && maxBookingSlot !== null && (selected_time.length == maxBookingSlot) ? 'opacity-50 disabled' : '']" 
                    @click="selectTime(item.start_time)" 
                    class="rounded-lg p-1 text-center border pointer time-slot">
                {{ item.service_time }}
            </button>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Loading from '../ArticleLoading.vue';
    import { eventBus } from '../../../main';

    export default {
        props: ['value', 'productUuid', 'slotsCount', 'maxBookingSlot'],

        components: { Loading },

        data() {
            return {
                avaiable_days: null,
                avaiable_times: null,

                loading: false,
                loading_time: false,

                // lang: 'en',
                selected_date: this.value[0] !== undefined ? this.value[0].split(' ')[0] : null,
                selected_time: this.value.length ? this.value : [],

                start_date: null,
                end_date: null,
            }
        },

        created() {
            eventBus.$on('update-slot-calendar', data => {
                this.avaiable_days = null;
                this.avaiable_times = null;
                this.selected_date = this.value[0] !== undefined ? this.value[0].split(' ')[0] : null;
                var start_date = moment().startOf('month').format("YYYY-MM-DD");
                var end_date = moment().endOf('month').format("YYYY-MM-DD");
                
                if(this.selected_date){
                    this.fetchAvailableTimes(this.selected_date);
                }

                this.fetchAvailableDays(start_date, end_date); 
            });            
        },

        // mounted() {
        //     this.$nextTick(() => {
        //         this.lang = this.$root.currentLanguage == 'ru' ? 'ru' : (this.$root.currentLanguage == 'fr' ? 'fr' : 'en');
        //     });
        // },

        computed: {
            cssProps() { 
                return {
                    '--main-color': this.$root.hotelColor(),
                }
            },
            lang() {
                return this.$root.currentLanguage == 'ru' ? 'ru' : (this.$root.currentLanguage == 'fr' ? 'fr' : 'en');
            }
        },

        watch: {
            selected_date(val) {
                this.selected_time = [];
                this.fetchAvailableTimes(val);
            }
        },

        methods: {
            disabledDays(date) {   
                if (this.avaiable_days == null) {
                    return false;
                }          
                         
                var currentDate = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
                return this.avaiable_days.filter(item => item.service_date == currentDate && item.available_timeslots > 0).length == 0
            },

            async fetchAvailableDays(start_date, end_date) {
                this.loading = true;
                var url = process.env.VUE_APP_API_URL + 'public/products/' + this.productUuid + '/check-availability-property';
                var params = {
                    start_date: start_date,
                    end_date: end_date,
                    type: 'days',
                    hotel_uuid: this.$root.hotel.uuid
                };

                try {
                    let response = await this.$http.get(url, {params: params});
                    this.avaiable_days = response.data.data;

                    this.loading = false;
                } catch(e) {
                    this.loading = false;
                }
            },

            async fetchAvailableTimes(date) {
                this.avaiable_times = null;
                this.loading_time = true;
                var url = process.env.VUE_APP_API_URL + 'public/products/' + this.productUuid + '/check-availability-property';

                var params = {
                    date: date, 
                    type: 'times',
                    hotel_uuid: this.$root.hotel.uuid
                };

                try {
                    let response = await this.$http.get(url, {params: params});
                    
                    this.loading_time = false;
                    this.avaiable_times = response.data.data;
                } catch(e) {
                    this.loading_time = false;
                }
            },

            handleCalendarChange(value, oldValue) {
                var start = moment(value);
                var start_date = start.format("YYYY-MM-DD");
                var end_date = start.endOf('month').format('YYYY-MM-DD');
                
                //this.avaiable_times = null;
                this.fetchAvailableDays(start_date, end_date);
            },

            isSelectedTime(item){
                return this.selected_time.indexOf(item) > -1;
            },

            selectTime(item) {                
                let itemPos = -1;
                if((itemPos = this.selected_time.indexOf(item)) > -1){
                    this.selected_time.splice(itemPos, 1);
                } else{
                    if (this.selected_time.length == this.maxBookingSlot) {
                        return false;
                    }

                    this.selected_time.push(item);
                }
                //this.selected_time = item;
                
                this.$emit('update:value', this.selected_time);
            }
        },
    }
</script>
<style>
.timeslot-calendar .mx-datepicker-main, .timeslot-calendar .mx-datepicker-inline {
    width: 350px!important;
    border-radius: 10px;
    border: none;
}
.timeslot-calendar .mx-calendar {
    width: 100%;
    padding: 40px 16px;
    padding-top: 12px;
    border-width: 1px;
    border-radius: 10px;
}

.timeslot-calendar .mx-table-date td, .timeslot-calendar .mx-table-date th{
  height: 36px;
  font-size: 15px;
}

.timeslot-calendar .mx-calendar-content .cell.active {
    color: #fff;
}

.timeslot-calendar .mx-table-date .cell.not-current-month div {
    display: none;
}

.mx-calendar-content .cell.active {
    background: var(--main-color);    
}

.active-time {
    background: var(--main-color);
}

.time-slot:hover {
    background: var(--main-color);
    color: #fff;
}
.time-slot.disabled:hover {
    background: white;
    color: #333;
}
.slot-times {
    width: 350px!important;
    transition: max-height .2s ease-in-out; 
    max-height: 0;
}
.max {
  max-height: 1000px;
  transition: max-height 2s ease-in-out;
}
</style>