<template>
  <transition name="modal">
  <div class="wrapper_popup h-full" id="modal_add_to_cart" v-if="product && visible">

    <div class="header_popup flex justify-between items-center h-12" :class="{ 'error' : errors.get('products_is_not_available') }">
      <div class="pl-4">
        <div>{{$root.getLangText(product.title)}}</div>
        <div class="text-red-500 pb-2 text-xs" v-if="errors.get('products_is_not_available')">{{ $root.getKeyWord('products_not_available') }}</div>
      </div>
      <a href="javascript:void(0)" class="close_popup" @click="product = null; visible = false">
        <svg width="48" height="48" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.2427 12.7574L12.7574 21.2426M12.7574 12.7574L21.2427 21.2426L12.7574 12.7574Z" stroke="#333333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>
    </div>

    <div class="content_popup px-4 pb-4">
      <div class="my-5">
        <div class="text-gray-600 section-description" v-if="product.descr" v-html="$root.getLangText(product.descr)=='<br>'?'':$root.getLangText(product.descr)"></div>

        <div class="flex items-center justify-between">
          <div class="w-1/2">
            <div class="spinner" v-if="product.show_quantity && product.is_popup">
              <v-input-quantity :value.sync="quantity" :min="1" :max="product.max_order_quantity == null ? 99 : parseInt(product.max_order_quantity)"></v-input-quantity>
            </div>
          </div>
          <div class="w-1/2 flex justify-end" v-if="parseFloat(price) > 0">
            <div class="price_product" v-html="$root.showPrice(price, is_price_from)"></div>
          </div>
        </div>
      </div>

      <app-properties-new 
        ref="property_product" 
        :property.sync="product.property" 
        :product-uuid="product.uuid" 
        :max-booking-slot="product.max_booking_slot"
        :quantity="quantity" 
        :schedule="product.schedule" 
        :errors="errors">
      </app-properties-new>

      <div class="form_cart mt-4">
        <div class="wrapper_select mb-4 form_group"  :class="[errors && errors.get('payment') ? 'dirty valid' : '']" v-if="$root.hotel.is_payment_property && price > 0 && displayHotelPayments && cart.hotel_payments && cart.hotel_payments.length > 0">
          <div class="flex pb-2">
            <div class="header_option" :class="[ errors && errors.get('payment') ? 'valid' : '']" v-text="$root.getKeyWord('pay_method')"></div>
            <span class="text-xs text-red-500 -mt-1 px-1"> *</span>
          </div>
          <select class="default_select appearance-none" v-model="cart.payment">
            <option :value="payment.id" v-for="payment in cart.hotel_payments" :key="payment.id">{{ $root.getLangText(payment.title) }}</option>
          </select>
        </div>

        <div class="mb-5 mb-4 form_group" :class="[errors && errors.get('room_number') ? 'dirty valid' : '']" v-if="displayRoomNumber">
          <div class="flex pb-2">
            <div class="header_option" v-text="roomNumberInputTitle()"></div>
            <span class="text-xs text-red-500 -mt-1 px-1"> *</span>
          </div>
          <div class="option">
            <input type="text" v-model="cart.room_number" class="h-4" title="property_room_number"/>
          </div>
        </div>

        <div>
          <app-properties-new ref="property_hotel" :property.sync="cart.property" :errors="errors"></app-properties-new>
        </div>

        <div class="form_group mt-5" v-if="$root.hotel.is_comment_property">
          <div class="header_option pb-2"  v-text="$root.getKeyWord('comments')"></div>
          <textarea id="message" v-model="cart.comment"></textarea>
        </div>

        <div v-if="$root.hotel.is_agreement_text && !$root.showConfirmationCheckBox()">
            <span class="text-xs text-gray-600 font-light" v-text="$root.getKeyWord('use_session')"></span>
            <span class="text-xs underline text-gray-600 font-light ml-1 cursor-pointer" v-text="$root.getKeyWord('public_offer')" @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')"></span>
        </div>


        <div  v-if="section.files && section.files.length > 0">
					<div class="text-xs flex justify-end items-center" v-for="file in section.files"  :key="file">
						<a :href="$root.getImage($root.getLangText(file.files))" v-if="$root.getImage($root.getLangText(file.files)).length > 0" class="border  px-4 py-2 my-2 text-blue font-light  text-black text-center rounded-full focus:outline-none flex items-center justify-center hover:bg-gray-300" target="_blank">
							<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#333" class="mr-3"><g><rect fill="none" height="24" width="24"/></g><g><path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z"/></g></svg>
							{{ file.title.length === 0 ? $root.getKeyWord('download') : $root.getLangText(file.title) }}
						</a>
					</div>
				</div>

        <app-uk-privacy-policy v-if="$root.showConfirmationCheckBox()" :check-agreement.sync="checked_agreement" :check-box-error.sync="check_box_error">
        </app-uk-privacy-policy>

        <button v-if="!loading" class="default_green_btn mt-4" @click="createOrder" v-text="buttonOrderText"></button>
        <loading :width="'w-14'" :height="'h-14'" v-else></loading>
      </div>

    </div>
  </div>
  </transition>
</template>

<script>

import AppPropertiesNew from '@/components/article/Properties/index';
import AppUkPrivacyPolicy from '@/components/article/AppUkPrivacyPolicy';
import Loading from '../../components/article/ArticleLoading.vue';
import PaymentPageSdk from '@raiffeisen-ecom/payment-sdk';
import {IsAnyProductGotRoomNumberOptionMixin} from '@/components/article/IsAnyProductGotRoomNumberOptionMixin';
import { eventBus } from '../../main';

class Errors {
    constructor() {
        this.errors = {};
    }

    get(field) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }
    }

    searchByKey(field) {
        return Object.keys(this.errors).filter(item => item.indexOf(field) > -1).length > 0;
    }

    record(errors) {
        this.errors = errors;
    }
}

export default {
  name: 'article-product-fast-cart',

  mixins: [IsAnyProductGotRoomNumberOptionMixin],

  components: {AppPropertiesNew, AppUkPrivacyPolicy, Loading},

  props: ['productId'],

  data() {
    return {
      quantity: 1,
      checked_agreement: this.$root.showConfirmationCheckBox() ? false : true,
      price: 0,
      status_payment: 'NONE',
      intervalCheckPayment: null,
      visible: false,
      product: null,
      section: null,
      parent_section: null,
      cart: null,
      loading: false,
      is_price_from: false,
      errors: new Errors(),
      check_box_error:false,
    }
  },

  created () {
      eventBus.$on('product-fast-cart', data => {
          this.$root.cleanCart();

          this.product = JSON.parse(data.product);
          this.section = data.section;
          this.parent_section = data.parent_section;
          this.price = parseFloat(data.product.price);
          this.visible = true;
          this.loading = false;
          this.errors = new Errors();

          this.is_price_from = this.product.is_price_from;
          let productIds = [this.product.id]
          var property = null;
          var params = {
            hotel_uuid: this.$root.hotel.uuid,
            filter: {
              product_ids: productIds
            }
          }
          let url = process.env.VUE_APP_API_URL + `public/properties`;
          this.$http.get(url, {params: params}).then(response => {
            this.cart.property = response.data.data
          });

          this.cart =  localStorage['cart'] !== undefined
            ? JSON.parse(localStorage['cart'])
            : {
                room_number: this.getDefaultRoomNumber(),
                payment: null,
                comment: '',
                hotel_id: this.$root.hotel.id,
                property: property,
                uuid: '',
                is_temporary: false,
                products:[]
            };

          this.cart.property = property;
          this.cart.payment = null;
          this.cart.comment = '';
          this.cart.hotel_payments = null;
          this.cart.is_temporary = false;

          url = process.env.VUE_APP_API_URL + `public/cart/payments`;
          params = {
              hotel_uuid: this.$root.hotel.uuid,
              product_ids: productIds
          };
          
          this.$http.get(url, {params: params}).then(response => {
              this.cart.hotel_payments = response.data.data
          });

          this.$nextTick(() => {
            eventBus.$emit('update-slot-calendar');
          });          
      });
  },

  watch: {
      quantity(val) {
          if (val == undefined) return;

          this.setTotalPrice();
      },

      product: {
          handler: function(newValue) {
              this.setTotalPrice();
          },
          deep: true
      },

      visible(val) {
        this.$root.freezeActivePageScroll(val);
      }
  },

  computed: {
    displayRoomNumber() {
      var products = [];
      products.push({
        parent_section: this.parent_section,
        section: this.section
      });

      return this.$root.hotel.is_room_number_property && this.$root.hotel.limit_room.length > 0 && this.isAnyProductGotRoomNumberOption(products);
    },


    buttonOrderText() {
			let btnOrdTextProduct = this.$root.getLangText(this.product.button_order_text);

			if (btnOrdTextProduct)
				return btnOrdTextProduct

			return this.$root.getKeyWord('send_to_cart');
		},

    displayHotelPayments() {
      return true;
    },
    
    selectedPayment() {
      try {
        return this.$root.hotel?.payments?.find(item => item.id === this.cart.payment) || null;
      } catch (e) {
        console.error(e);
        return null;
      }
    }

  },

  methods: {
    createOrder() {
      if(this.$root.showConfirmationCheckBox() && !this.checked_agreement){
        this.check_box_error = true;
        return
      }
      this.sendOrder();
    },

    sendOrder() {
      if (this.loading)
        return false;
      
      this.loading = true;

        this.cart.uuid = this.$root.generate_and_set_uuid();
        this.cart.products = [
          {
            product: this.product,
            quantity: this.quantity,
            total_price: this.price,
            from_recommendation: this.from_recommendation,
            section: this.section,
            parent_section: this.parent_section,
          }
        ];

        this.cart.hotel_id = this.$root.hotel.id;

        let sendCart = this.$root.buildCart(this.cart);
        sendCart.language = this.$root.currentLanguage;

        this.$http.post(process.env.VUE_APP_API_URL + 'orders', sendCart).then(({data}) => {
          
          if (data.data.order !== undefined && data.data.order.uuid) {
            this.$root.store_order_uuid(data.data.order.uuid);
          }
          
          var payment_url = null;
          if (this.selectedPayment && this.selectedPayment.ptype_id === 'online') {
            if (data.data.pay_data?.response?.formUrl !== null) {
              payment_url = data.data.pay_data.response.formUrl;
              this.successOrderPopup(payment_url);
              return data;
            }
          }

          this.successOrderPopup();
          this.loading = false;

          return data;
        }).catch(error => {
            this.loading = false;
            console.error(error);
            this.errors.record(error.response.data.data);
        });
    },

    successOrderPopup(payment_url = null) {
        // hide cart, display success popup
        this.visible = false;
        var firstSection = this.cart.products[0].section;
        eventBus.$emit('order-success-splash', {
          section:  this.cart.products[0].section,
          parent_section:  this.cart.products[0].parent_section,
          total_price: this.product.price,
          payment_url: payment_url,
        });

        this.$root.cleanCart();
    },

    getDefaultRoomNumber() {
      if (this.$root.hotelCode == 'palmira-bc') return '';

      return (localStorage.getItem('fs_room_number') != undefined) ? localStorage.getItem('fs_room_number') : '';
    },

    setTotalPrice() {
        if (this.product === undefined || this.product === null)
          return;

        let total = 0;
        let product_price = parseFloat(this.product.price);
        let property_total_price = 0;

        // add words from for price get from product
        this.is_price_from = this.product.is_price_from;

        // check if product has selected properties
        if (this.product.property_kit_id > 0 && this.product.property.id !== undefined) {
          this.product
            .property
            .properties
            .filter(item => item.value !== '' && item.value !== null && item.price_type_id !== 'none')
            .map(item => {
              if (item.price_type_id === 'replace')
                product_price = 0;

              if (item.price_type_id === 'plus' || item.price_type_id === 'replace') {
                if (item.ptype_id == 'select') {
                  property_total_price += item.attrs
                    .filter(item_attr => parseInt(item.value) === item_attr.id && item_attr.price !== null)
                    .reduce(function (acc, item_attr) { return acc + parseFloat(item_attr.price) * item_attr.quantity; }, 0);
                } else if (item.ptype_id == 'checkbox') {
                  var attr_price = item.attrs
                    .filter(item_attr => item_attr.checked && item_attr.price !== null)
                    .reduce(function (acc, item_attr) {
                      console.log(item_attr);
                      return acc + parseFloat(item_attr.price) * item_attr.quantity;
                    }, 0);

                  attr_price = attr_price === 0 && item.price > 0 ? item.price : attr_price;

                  property_total_price += attr_price;
                } else if (item.price > 0) {
                  property_total_price += item.price;
                }
              }
          });
        }

        console.log(property_total_price);

        this.price = this.quantity * (product_price + parseFloat(property_total_price));

        if (this.price !== product_price)
          this.is_price_from = false;
    }
  }
}
</script>

<style>
  .multiselect__tags {
    min-height: 34px;
    display: block;
    padding: 8px 40px 0 8px;
    border: 1px solid #F2F2F2;
    background: #fff;
    font-size: 14px;
    height: 34px;
    border-radius: 8px;
  }

  .multiselect__select:before {
    display: none;
  }

  .multiselect__input, .multiselect__single {
    line-height: 14px;
  }

  .grecaptcha-badge{
      right: -1000px!important;
  }

</style>
