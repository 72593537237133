import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import VueNumericInput from 'vue-numeric-input';
//
import {VueMaskDirective} from 'v-mask';
import vueScrollTo from 'vue-scrollto';
import VueLazyload from 'vue-lazyload';
import checkView from 'vue-check-view';
import VModal from 'vue-js-modal';
import VueTheMask from 'vue-the-mask'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/fr';
import 'vue2-datepicker/locale/pl';
import 'vue2-datepicker/locale/nl';
import 'vue2-datepicker/locale/de';
import 'vue2-datepicker/locale/et';
import 'vue2-datepicker/locale/zh-cn';
import en from 'vue2-datepicker/locale/en';
import './components/vue2-datepicker/locale/ba.js';
import moment from 'moment';
import {Drawer, Upload, Carousel} from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import axios from 'axios';
import {template} from "lodash";
import { db } from './firebase'; 

Vue.use(VueRouter)

Vue.use(VueNumericInput);

Vue.directive('mask', VueMaskDirective);

Vue.use(vueScrollTo);

Vue.use(VueLazyload);

Vue.use(checkView);

Vue.use(VModal, {dialog: true});

Vue.use(VueTheMask);

const updateCalendars = DatePicker.CalendarRange.methods.updateCalendars;

DatePicker.CalendarRange.methods.updateCalendars = function (...args) {
    updateCalendars.apply(this, args);

    this.calendars = this.calendars.map((v) => {
        const next = new Date(v);
        next.setMonth(next.getMonth() - 1);
        return next;
    });
};

Vue.use(DatePicker);
Vue.use(Drawer);
Vue.use(Upload);
Vue.use(Carousel);

// register globally
Vue.component('v-input-quantity', require('./components/article/AppInputQuantity.vue').default);

export const eventBus = new Vue();

Vue.prototype.$http = axios;

const Home = () => import('./pages/Home/index.vue');
const Products = () => import('./pages/Product/index.vue');
const SkipTheClean = () => import('./pages/SkipTheClean/index.vue');
const StaticPage = () => import('./pages/StaticPage/index.vue');
const Sections = () => import('./pages/Sections/index.vue');
const LotteryPage = () => import('./pages/LotteryPage/index.vue');
const AR = () => import('./pages/AR/index.vue');
const ARPromo = () => import('./pages/ARPromo/index.vue');
const NotFound = () => import('./pages/NotFound.vue');
const ChatPage = () => import('./pages/Chat/index.vue');
const BookingPage = () => import('./pages/Booking/index.vue');
const PaymentSuccess = () => import('./pages/PaymentSuccess.vue');
const PaymentError = () => import('./pages/PaymentError.vue');
const LocationOccupancyPage = () => import('./pages/LocationsOccupancy/index.vue');

Vue.config.productionTip = false;

const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '*', name: 'NotFound', component: NotFound},
        {path: '/404', component: NotFound},
        {path: '', name: 'home', component: Home},
        {path: '/home', name: 'home', component: Home},
        {
            path: '/sections/:id', name: 'sections', component: Sections, beforeEnter: (to, from, next) => {
                localStorage.setItem('prev_route', from.path);
                next();
            }
        },
        {
            path: '/products/:id', name: 'main', component: Products, beforeEnter: (to, from, next) => {
                if(from.path == "/" && localStorage.getItem("prev_route_old") !== null){
                    localStorage.setItem('prev_route', localStorage.getItem("prev_route_old"));
                    localStorage.removeItem("prev_route_old")
                }else{
                    localStorage.setItem('prev_route', from.path);
                }
                next();
            }
        },
        /** helper pages */
        {path: '/skip-the-clean', component: SkipTheClean},
        {path: '/payment/success', component: PaymentSuccess},
        {path: '/payment/error', component: PaymentError},
        {path: '/section-page/:id', component: StaticPage},
        {path: '/lottery/:id', component: LotteryPage},
        {path: '/ar-promo', name: 'ar-promo', component: ARPromo},
        {path: '/ar', name: 'ar', component: AR},
        {path: '/chat', name: 'chat', component: ChatPage},
        {path: '/booking', name: 'booking', component: BookingPage},
        {path: '/locations-occupancy', name: 'locations-occupancy', component: LocationOccupancyPage},
    ],
});


Vue.router = router;
App.router = Vue.router;


router.beforeEach((to, from, next) => {
    // This function is called before every route change

    // You can access the 'to' and 'from' route objects to check the path changes
    const newPath = to.path;
    const oldPath = from.path;

    eventBus.$emit('update-bottom-navigation-menu', {newPath: newPath});

    // You must call 'next()' to allow the route change to proceed
    next();
});


Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        var vm = vnode.context;
        var callback = binding.value;

        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                return callback.call(vm, event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

Vue.mixin({
    methods: {
        getCoName() {
            var company = "2ROOMZ";
            var site = window.location.hostname.split('.');
            var favicon = document.getElementById("favicon");

            if (site.length > 1 && site[1] == 'dgstay') {
                company = 'Dgstay';
                favicon.href = "/favicon_dgstay.png";
            }

            document.title = company;

            return company;
        },

        isScheduledProduct(product) {
            if (!product.property || !product.property.properties) {
                return false;
            } else {
                return product.property.properties.findIndex(function (property) {
                    return property.ptype_id == 'datetime' && property.pcode_id == "scheduled";
                }) > -1;
            }
        },

        isMobile() {
            if (navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/Windows Phone/i)
            ) {
                return true;
            } else {
                return false;
            }
        },

        isDesktopVersion() {
            return this.$root.hotel && this.$root.hotel.is_active_desktop_mode && !this.isMobile() && this.$router.currentRoute.name == 'home';
            //return (window.location.host === 'testpage.2roomz.com' || window.location.host === 'novoteltemplatev2.dgstay.com') && !this.isMobile() && this.$router.currentRoute.name == 'home';
        },

        uuidv4() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        },

        generate_and_set_uuid() {
            let uuid = this.uuidv4();
            let uuids = localStorage.getItem('fs_uuids') && localStorage.getItem('fs_uuids') !== undefined ? JSON.parse(localStorage.getItem('fs_uuids')) : [];

            uuids.push(uuid);

            //localStorage.setItem('fs_uuids', JSON.stringify(uuids));
            return uuid;
        },
        
        store_order_uuid(uuid) {
            let uuids = localStorage.getItem('fs_uuids') && localStorage.getItem('fs_uuids') !== undefined ? JSON.parse(localStorage.getItem('fs_uuids')) : [];

            uuids.push(uuid);

            localStorage.setItem('fs_uuids', JSON.stringify(uuids));
            return uuid;            
        },

        getLasyImage(src) {
            let lazyImgObj = {
                src: src,
                error: require(`@/assets/image/loading_product.svg`),
                loading: require(`@/assets/image/loading_product.svg`)
            };

            return lazyImgObj;
        },

        displayScheduleText(record, withDefault = true) {
            if (!record || record === undefined || Object.values(record).length === 0 || !record.schedule)
                return '';

            if (this.$root.getLangText(record.schedule.descr))
                return this.$root.getLangText(record.schedule.descr);

            if (record.schedule.days.length > 0) {
                var text = this.$root.getKeyWord('working_hours') + ': ';
            
                var scheduleDescriptions = record.schedule.days.map(item => {
                    const weekdays = this.getWeekdays(item.days);
                    const timeRange = `${this.splitFormatTime(item.start_time)} - ${this.splitFormatTime(item.end_time)}`;
                    
                    return weekdays ? `${timeRange} (${weekdays})` : timeRange;
                });
            
                return text + scheduleDescriptions.join(', ');
            }

            if (withDefault)
                return this.$root.getKeyWord('available_soon');

            return '';
        },

        splitFormatTime(time) {
            var splitTime = time.split(':');
            return splitTime.length > 2 ? splitTime[0] + ':' + splitTime[1] : time;
        },


        getWeekdays(days) {
            if (days.every(day => day)) {
                return ''; // No need to add anything if all days are true
            }
            var currLan = sessionStorage.getItem('lang') != undefined ? sessionStorage.getItem('lang') : 'en'
            const weekdaysTranslations = {
                en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                es: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
                fr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                zh: ['日', '一', '二', '三', '四', '五', '六'],
                ja: ['日', '月', '火', '水', '木', '金', '土'],
                ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                pt: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
                ar: ['أحد', 'إثنين', 'ثلاثاء', 'أربعاء', 'خميس', 'جمعة', 'سبت'],
                hi: ['रवि', 'सोम', 'मंगल', 'बुध', 'गुरु', 'शुक्र', 'शनि'],
              };            

            const weekdays = weekdaysTranslations[currLan] || weekdaysTranslations['en'];


            let result = [];
            let rangeStart = null;
    
            for (let i = 0; i < days.length; i++) {
                if (days[i]) {
                    if (rangeStart === null) {
                        rangeStart = weekdays[i];
                    }
                } else {
                    if (rangeStart !== null) {
                        if (rangeStart === weekdays[i - 1]) {
                            result.push(rangeStart);
                        } else {
                            result.push(`${rangeStart}-${weekdays[i - 1]}`);
                        }
                        rangeStart = null;
                    }
                }
            }
    
            // Handle the case where the last day is part of a range
            if (rangeStart !== null) {
                if (rangeStart === weekdays[days.length - 1]) {
                    result.push(rangeStart);
                } else {
                    result.push(`${rangeStart}-${weekdays[days.length - 1]}`);
                }
            }
    
            // Handle single days not in a range
            const singleDays = result.filter(day => !day.includes('-'));
            const ranges = result.filter(day => day.includes('-'));
    
            return [...singleDays, ...ranges].join(', ');

        },
    },

    filters: {
        dbDateFormat(date) {
            return moment.utc(date).local().format('YYYY-MM-DD');
        },

        dateFormat(date) {
            return moment.utc(date).local().format('DD.MM.YYYY HH:mm');
        },
        dateFormatTZ(date) {
            return moment(date).format('DD.MM.YYYY HH:mm');
        },
        monthFormat(date) {
            return moment.utc(date).local().format('DD.MM');
        },

        timeFormat(date) {
            if (date == '') return '';
            return moment.utc(date).local().format('HH:mm');
        },

        dateFromNow(date) {
            if (date == '') return '';
            return moment.utc(date).local().fromNow();
        },
    }
});


new Vue({
    render: h => h(App),
    router,

    data() {
        return {
            hotel: [],
            logo: '',
            scrollTop: 0,
            keyWords: [],
            languages: [],
            loading: false,
            cart: {
                products: [],
            },
            cart_total_price: 0,
            cart_total_quantity: 0,
            cart_free_delivery: false,
            title: '',
            public_info: {
                privacy_policy: '',
                full_agreement: '',
                is_adult_text: ''
            },
            currentLanguage: sessionStorage.getItem('lang') != undefined ? sessionStorage.getItem('lang') : 'en',
            weather: '',
            show_announce: false,
        }
    },

    created() {
        this.doRtl();
    },

    mounted() {
        this.getHotel();
    },

    watch: {
        'cart.payment': function () {
            if (this.hotel.payments) {
                eventBus.$emit('updated-cart-payment');
            }
        },
        currentLanguage(val) {
            sessionStorage.setItem('lang', val);

            this.doRtl();
        }
    },

    computed: {
        hotelCode() {
            let full = window.location.host;

            if (full === 'service.marriottimperialplaza.moscow')
                full = 'marriottimperialplaza.2roomz.com';

            if (full === 'beeroteka-park.ru')
                full = 'biroteka.2roomz.com';                

            let parts = full.split('.');
            return (process.env.VUE_APP_ENV === 'development') ? process.env.VUE_APP_TEST_HOTEL : parts[0];
        },

        template() {
            if (location.pathname.indexOf('/chat') > -1) {
                return '';
            }

            return (this.hotel) ? this.hotel.template : '';
        }

    },

    methods: {
        changeViewport() {
            const head = document.head;
            const meta = document.querySelector('meta[name="viewport"]');
            const newMeta = document.createElement('meta');

            newMeta.setAttribute('name', 'viewport');
            newMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=5');

            head.removeChild(meta);
            head.appendChild(newMeta);
        },

        buildCart(cart) {
            let sendCart = JSON.parse(JSON.stringify(cart));

            sendCart.products = sendCart.products.map(item => {
                delete item.section;
                delete item.parent_section;

                if (this.isScheduledProduct(item.product)) {
                    let _this = this;
                    let scheduledProperty = item.product.property.properties.find((property) => {
                        return property.ptype_id == 'datetime' && property.pcode_id == "scheduled";
                    });
                    let slotPropertyId = item.product.property.properties.findIndex((property) => {
                        return property.ptype_id == 'datetime' && property.pcode_id == "scheduled";
                    });
                    let times = scheduledProperty.value;

                    item.quantity = times.length;

                    item.product.property.properties.splice(slotPropertyId, 1);
                    times.forEach(function (slotTime, index) {
                        let insertedProperty = JSON.parse(JSON.stringify(scheduledProperty));
                        insertedProperty.value = slotTime;
                        item.product.property.properties.push(JSON.parse(JSON.stringify(insertedProperty)));
                    });
                }

                item.product = {
                    id: item.product.id,
                    property: this.buildProperty(item.product.property),
                };
                return item;
            });

            sendCart.property = this.buildProperty(sendCart.property);

            return sendCart;
        },

        buildProperty(property) {
            if (!property || property === undefined)
                return null;

            property.properties = property.properties.map(item => {
                if (item.pcode_id == 'name')
                    localStorage.setItem('fs_name', item.value);

                return {
                    id: item.id,
                    value: item.value,
                    ptype_id: item.ptype_id,
                    attrs: item.attrs.map(item_attr => {
                        return {
                            id: item_attr.id,
                            title: item_attr.title,
                            quantity: item_attr.quantity,
                            checked: item_attr.checked,
                        }
                    })
                }
            });

            return property;
        },

        updateCart() {
            try {
                if (localStorage['cart'] == undefined || localStorage['cart'] == null)
                    return;

                var cart = JSON.parse(localStorage['cart']);
                var total_price = 0;
                var total_quantity = 0;

                this.$root.cart = cart;

                this.$root.cart.products.map(item => {
                    total_price += item.total_price;
                    total_quantity += item.quantity;
                });

                this.cart_total_quantity = total_quantity;
                this.cart_free_delivery = this.$root.cart.products.filter(item => item.product.free_delivery).length === this.$root.cart.products.length;

                this.$root.cart.products.forEach(item => {
                    if (total_price > 0 && !item.product.free_delivery) {
                        if (this.hotel.discount_percent > 0) {
                            total_price += (parseFloat(item.total_price) * this.hotel.discount_percent) / 100;
                        }
                    }
                })

                if (total_price > 0 && !this.cart_free_delivery && this.hotel.discount_percent <= 0) {
                    total_price += this.hotel.discount_price;
                }


                this.cart_total_price = total_price;

                try {
                    let productIds = this.$root.cart.products.map(item => item.product.id)
                    var property = JSON.parse(JSON.stringify(this.$root.hotel.property));
                    this.$root.cart.property = property;
                    var params = {
                        hotel_uuid: this.$root.hotel.uuid,
                        filter: {
                            product_ids: productIds
                        }
                    }
                    let url = process.env.VUE_APP_API_URL + `public/properties`;
                    this.$http.get(url, {params: params}).then(response => {
                        this.$root.cart.property = response.data.data
                    });

                    this.updateCartPayments();
                } catch (e) {
                    console.error(e);
                }
            } catch (e) {
                console.error(e);
            }
        },

        updateCartPayments() {            
            let productIds = this.$root.cart.products.map(item => item.product.id);
            if (productIds.length === 0) {
                console.log('No products in the cart.');
                return;
            }
            var url = process.env.VUE_APP_API_URL + `public/cart/payments`;
            var params = {
                hotel_uuid: this.$root.hotel.uuid,
                product_ids: productIds
            };
            this.$http.get(url, {params: params}).then(response => {
                this.$root.cart.hotel_payments = response.data.data
            });
        },

        cleanCart() {
            try {
                var cart = JSON.parse(localStorage['cart']);
                try {
                    var property = this.$root.hotel.property;
                    property.properties = this.$root.hotel.property.properties.map(item => {
                        if (cart.property.properties.filter(one => one.id == item.id).length > 0) {
                            item.value = cart.property.properties.filter(one => one.id == item.id)[0].value;
                        }
                        return item;
                    });
                } catch (e) {
                    console.error(e);
                }

                cart.products = [];
                cart.hotel_id = this.$root.hotel.id;
                cart.property = property;
                cart.uuid = '';
                cart.is_temporary = false;

                this.cart = cart;
                localStorage['cart'] = JSON.stringify(cart);
            } catch (e) {

            }
        },

        async getHotel() {
            var hotel_url = process.env.VUE_APP_API_URL + 'hotels/' + this.hotelCode;

            const debugMode = this.$root.getQueryVariable('debug_mode');
            if (debugMode) {
                // Append debug_mode to the URL if present
                hotel_url += '?debug_mode=' + encodeURIComponent(debugMode);
            }
        
            await this.$http.get(hotel_url)
                .then(response => {
                    
                    if (response["data"]["status"] === 404 || !response.data.data || !response.data.data.hotel) {
                        this.$router.push('/404');
                        return;
                    }

                    this.hotel = response.data.data.hotel;
                    this.keyWords = response.data.data.words;
                    this.languages = response.data.data.languages;

                    this.initLanguageToSite(this.languages);
                    this.updateCart();
                    this.setLogo();
                    this.setMetrics();
                    this.getPublicInformation('is_adult_18');
                    this.getPublicInformation('is_adult_16');

                    if (this.template === 'video_template' || this.template === 'slider_template') {
                        this.getWeatherInfo();
                    }

                    if (this.hotel.favicon) {
                        this.setFavicon(this.getImage(this.hotel.favicon));
                    }

                    let room = this.$root.getQueryVariable('set-room');
                    if (room !== undefined && /\d/.test(room) === true ) {
                        localStorage.setItem('fs_room_number', decodeURIComponent(room));
                    }

                    let sendUrl = this.$root.getQueryVariable('send-url');
                    let openProductId = this.$root.getQueryVariable('open-product-id');
                    let getPropertyId = this.$root.getQueryVariable('property-id');
                    let getPropertyValue = this.$root.getQueryVariable('property-value');

                    eventBus.$emit('run-chat');
                    eventBus.$emit('get-orders');

                    if (sendUrl) {
                        let send_email = this.$root.getQueryVariable('send-email');
                        if (send_email) {
                            sessionStorage.setItem('email', decodeURIComponent(send_email));
                        }
                        sendUrl = decodeURIComponent(sendUrl);
                        document.getElementById('logo').style.display = 'none';
                        document.getElementById('logo2').style.display = 'block';
                        this.$router.push(window.atob(sendUrl));
                    } else if (openProductId) {
                        this.$router.push('/products/' + parseInt(openProductId));
                    } else if (this.hotel.start_page != "" && parseInt(this.hotel.start_page) > 0) {
                        if (window.location.pathname != "/404") {
                            this.$router.push('/products/' + parseInt(this.hotel.start_page));
                        }
                    }

                    if (getPropertyId && getPropertyValue) {
                        this.setPropertyToLocalStorage(getPropertyId, decodeURIComponent(getPropertyValue));
                    }
                    this.$nextTick(() => {
                        document.title = this.getLangObject(this.hotel.title);
                        if ((this.template !== 'video_template' && this.template !== 'slider_template') && this.show_announce === false && location.pathname.indexOf('/products') < 0 && location.pathname.indexOf('/sections') < 0 && location.pathname.indexOf('/lottery') < 0 && location.pathname.indexOf('/section-page') < 0) {
                            eventBus.$emit('modal-announcement', {
                                parent_section_id: null,
                            });
                            this.show_announce = true;
                        }
                        else if ((this.template === 'video_template' || this.template === 'slider_template') && this.show_announce === false && localStorage.getItem('is_second_screen') === 'true' && location.pathname.indexOf('/products') < 0 && location.pathname.indexOf('/sections') < 0 && location.pathname.indexOf('/lottery') < 0 && location.pathname.indexOf('/section-page') < 0) {
                            eventBus.$emit('modal-announcement', {
                                parent_section_id: null,
                            });
                            this.show_announce = true;
                        }
                    });
                })
                .catch((e) => {
                    console.log(e);
                    //this.$router.push('/404');
                });
        },

        async getPublicInformation(type) {
            var hotel_url = process.env.VUE_APP_API_URL + 'hotels/' + this.hotel.id + '/get-public-info/' + type;

            if (this.public_info[type] === '') {
                await this.$http.get(hotel_url)
                    .then(response => {
                        switch (type) {
                            case 'privacy_policy':
                                this.public_info.privacy_policy = response.data;
                                break;
                            case 'full_agreement':
                                this.public_info.full_agreement = response.data;
                                break;
                            case 'is_adult_18':
                                this.public_info.is_adult_text = response.data;
                                break;
                            case 'is_adult_16':
                                this.public_info.is_16_text = response.data;
                                break;
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        },

        async getWeatherInfo() {
            var hotel_url = process.env.VUE_APP_API_URL + 'hotels/' + this.hotel.id + '/get-weather-info';

                await this.$http.get(hotel_url)
                    .then(response => {
                        if(response.data !== 'Unable to fetch weather data') {
                            this.weather = response.data;
                        }
                        })
                    .catch((e) => {
                        console.log(e);
                    });
        },

        getKeyWord(key) {
            return (this.keyWords[key] != undefined && this.keyWords[key][this.currentLanguage] != undefined) ? this.keyWords[key][this.currentLanguage] : '';
        },

        getLangText(text) {
            return (text != undefined && text[this.currentLanguage] != undefined) ? text[this.currentLanguage] : '';
        },

        getLangObject(obj) {
            return (obj != undefined && obj[this.currentLanguage] != undefined) ? obj[this.currentLanguage] : '';
        },

        getImage(link, extra_link = null) {
            if (!link && !extra_link) return '';


            if (extra_link) {
                return process.env.VUE_APP_IMG_URL + extra_link.replace(process.env.VUE_APP_IMG_URL, '');
            }

            return process.env.VUE_APP_IMG_URL + link.replace(process.env.VUE_APP_IMG_URL, '');
        },

        getFile(link) {
            if (!link) return '';

            return link.indexOf('.pdf') > -1
                ? process.env.VUE_APP_API_URL + "pdf-viewer?file=" + process.env.VUE_APP_IMG_URL + link + '&back-url=' + window.location.href
                : process.env.VUE_APP_IMG_URL + link;
        },

        showPrice(price, is_price_from = false) {
            let from = (is_price_from) ? this.getKeyWord('from') + ' ' : '';
            let currency = '<div class="mx-1 price_currency inline-block">' + this.hotel.currency + '</div>';

            if (this.currentLanguage === 'ko') {
                return (parseFloat(price) > 0)
                    ? parseFloat(parseFloat(price).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + currency + from
                    : '';
            }

            if (this.currentLanguage === 'ar' || this.currentLanguage === 'il') {
                return (parseFloat(price) > 0)
                    ? from + parseFloat(parseFloat(price).toFixed(2)) + currency
                    : '';
            }

            return (parseFloat(price) > 0)
                ? from + parseFloat(parseFloat(price).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + currency
                : '';
        },

        setLogo() {
            let logo = this.hotel.logo;
            if (this.hotel.ext_data['logos'] != undefined && this.hotel.ext_data['logos'][this.currentLanguage] != undefined) {
                logo = this.hotel.ext_data['logos'][this.currentLanguage]
            }

            this.logo = process.env.VUE_APP_IMG_URL + logo;
        },

        setFavicon(favicon_url) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.rel = 'shortcut icon';
            link.href = favicon_url;
            document.getElementsByTagName('head')[0].appendChild(link);

            var link = document.createElement('link');
            link.rel = 'apple-touch-icon';
            link.href = favicon_url;
            document.getElementsByTagName('head')[0].appendChild(link);
        },

        freezePageScroll() {
            if (document.getElementsByClassName('overlist').length > 0) {
                this.scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
                document.querySelector('html').classList.add('freeze-page');
                document.querySelector('body').classList.add('freeze-page');
            } else {
                let scrollTop = this.scrollTop;
                document.querySelector('html').classList.remove('freeze-page');
                document.querySelector('body').classList.remove('freeze-page');

                window.scrollTo(0, scrollTop);
                this.scrollTop = 0;
            }
        },

        freezeActivePageScroll(disable) {
            if (disable) {
                this.scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
                document.querySelector('html').classList.add('freeze-page');
                document.querySelector('body').classList.add('freeze-page');
            } else {
                let scrollTop = this.scrollTop;
                document.querySelector('html').classList.remove('freeze-page');
                document.querySelector('body').classList.remove('freeze-page');

                window.scrollTo(0, scrollTop);
                this.scrollTop = 0;
            }
        },

        doRtl() {
            let htmlEl = document.querySelector("html");
            let dirRtl = htmlEl.getAttribute('dir');

            if (this.currentLanguage == 'il' || this.currentLanguage == 'ar') {
                htmlEl.setAttribute('dir', 'rtl');
            } else {
                htmlEl.removeAttribute('dir');
            }

            if (dirRtl == 'rtl') {
                localStorage.setItem("prev_route_old",localStorage.getItem('prev_route'));
                window.location.reload();
            }
        },

        getBrowserLanguage() {
            let lang = navigator.languages && navigator.languages[0] || // Chrome / Firefox
                navigator.language ||   // All browsers
                navigator.userLanguage; // IE <= 10

            if (lang && lang.indexOf('-') > -1) {
                lang = lang.split('-')[0];
            }
            return lang;
        },

        initLanguageToSite(languages) {
            var browserLanguage = this.getBrowserLanguage();
            var storageLanguage = sessionStorage.getItem('lang');

            if (storageLanguage != undefined) return;

            if (this.hotel.hide_ru) {
                this.currentLanguage = languages[0].code;
            } else {
                this.currentLanguage = process.env.VUE_APP_SERVER === 'RU' ? 'ru' : 'en';
            }

            Object.keys(languages).find(key => {
                if (languages[key].code === browserLanguage) {
                    this.currentLanguage = languages[key].code;
                }
            });
        },

        setMetrics() {
            if (process.env.VUE_APP_ENV !== undefined && process.env.VUE_APP_ENV !== 'production') {
                return false;
            }

            try {
                if (this.hotel.ext_data.scripts !== null && (this.hotel.ext_data.scripts.length > 0)) {
                    let script = document.createElement('script');
                    script.setAttribute('type', 'text/javascript');
                    script.setAttribute('language', 'javascript');

                    let inlineScript = document.createTextNode(this.stripTags(this.hotel.ext_data.scripts));
                    script.appendChild(inlineScript);
                    document.getElementsByTagName('head')[0].appendChild(script);

                }
            } catch (e) {
                console.error(e);
            }

            try {
                // extra appends js
                var jivosite = '';
                switch (this.hotelCode) {
                    // case "b60":
                    //     jivosite = 'code.jivo.ru/widget/q6Ziobb2jB';
                    //     break;
                    // case "4ek":
                    //     jivosite = 'code-ya.jivosite.com/widget/jZBJg50ACy';
                    //     break;
                    // case "4ep":
                    //     jivosite = 'code.jivo.ru/widget/Eex5eAP68c';
                    //     break;
                    // case "4elk":
                    //     jivosite = 'code-eu1.jivosite.com/widget/ICicxU8X6h';
                    //     break;
                    case "hotelnikolskaya":
                        jivosite = 'code.jivo.ru/widget/N1D6ISSPd2';
                        break;
                }

                if (jivosite) {
                    let script = document.createElement('script');
                    script.setAttribute('type', 'text/javascript');
                    script.setAttribute('language', 'javascript');
                    script.src = '//' + jivosite;
                    script.async = true;
                    document.getElementsByTagName('head')[0].appendChild(script);
                }
            } catch (e) {
                console.error(e);
            }

            try {
                // Extra appends JS for Quicktext
                var quicktext = '';
            
                switch (this.hotelCode) {
                    case "bestwesternsantina":
                        quicktext = {
                            src: 'https://cdn.quicktext.im/widget.min.js',
                            attributes: {
                                'data-license': '9hwWs-SqXd',
                                'data-im-source': 'JZC8eiyO4W'
                            }
                        };
                        break;
                }
            
                if (quicktext) {
                    if (!document.querySelector(`script[src="${quicktext.src}"]`)) {
                        let script = document.createElement('script');
                        script.setAttribute('type', 'text/javascript');
                        script.src = quicktext.src;
                        script.async = true;
                        
                        for (const [key, value] of Object.entries(quicktext.attributes)) {
                            script.setAttribute(key, value);
                        }
                        
                        document.head.appendChild(script);
                    }

                    let noScript = document.createElement('noscript');
                    let noScriptLink = document.createElement('a');
                    noScriptLink.href = "https://www.quicktext.im/";
                    noScriptLink.title = "Hotel AI Chatbot and Guest Communication";
                    noScriptLink.innerText = "Quicktext hotel chatbot";
                    noScript.appendChild(noScriptLink);
                    document.body.appendChild(noScript);                    
                }
            } catch (e) {
                console.error("Error loading Quicktext script:", e);
            }            

            if (this.hotelCode == 'miracleon') {
                var newScript = document.createElement("script");

                // Set the type attribute to "text/javascript"
                newScript.type = "text/javascript";

                // Define the JavaScript code as a string
                var javascriptCode = `
                    (function(w) {
                        var q = [
                            ['setContext', 'TL-INT-miracleon-2roomz-com_2023-09-15', 'ru'],
                            ['embed', 'booking-form', {
                                container: 'tl-booking-form'
                            }],
                            ['embed', 'search-form', {
                                container: 'tl-search-form'
                            }],
                        ];
                        var h=["ru-ibe.tlintegration.ru","ibe.tlintegration.ru","ibe.tlintegration.com"];
                        var t = w.travelline = (w.travelline || {}),
                            ti = t.integration = (t.integration || {});
                        ti.__cq = ti.__cq? ti.__cq.concat(q) : q;
                        if (!ti.__loader) {
                            ti.__loader = true;
                            var d=w.document,c=d.getElementsByTagName("head")[0]||d.getElementsByTagName("body")[0];
                            function e(s,f) {return function() {w.TL||(c.removeChild(s),f())}}
                            (function l(h) {
                                if (0===h.length) return; var s=d.createElement("script");
                                s.type="text/javascript";s.async=!0;s.src="https://"+h[0]+"/integration/loader.js";
                                s.onerror=s.onload=e(s,function(){l(h.slice(1,h.length))});c.appendChild(s)
                            })(h);
                        }
                    })(window);
                `;

                // Set the JavaScript code as the content of the new script element
                newScript.textContent = javascriptCode;

                // Append the new script element to the head of the HTML document
                document.head.appendChild(newScript);
            }

        },

        stripTags(str) {
            return (str !== undefined && str !== null && str.length > 0) ? str.replace('for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}', '').replace(/<\/?[^>]+>/gi, '') : '';
        },

        yandexGoal(goal) {
            if (typeof window.ym === 'function') {
                console.log('Run reach goal ym function', goal);
                window.ym(this.$root.hotel.metric_num, 'reachGoal', goal);
            } else if (typeof window.yandexGoal === "function") {
                console.log('Run reach goal yandexGoal function', goal);
                window.yandexGoal(this.$root.hotel.metric_num, goal);
            }
        },

        getQueryVariable(variable) {
            let query = window.location.search.substring(1);
            let vars = query.split("&");
            for (let i = 0; i < vars.length; i++) {
                let pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return (false);
        },

        formatDateFromNow(datetime) {
            return moment.utc(datetime).locale(this.currentLanguage).local().fromNow();
        },

        formatWeekDay(week_day) {
            return moment().day(week_day).locale(this.currentLanguage).format('dd');
        },

        formatDate(datetime) {
            var dateFormat = process.env.VUE_APP_SERVER.toLowerCase() == 'ru' ? 'DD-MM-YYYY' : 'YYYY-MM-DD';
            return moment(datetime, dateFormat).format(dateFormat);
        },

        hotelColor() {
            let color = (this.hotel && this.hotel.color && this.hotel.color.length == 7 && this.hotel.set_default_color) ? this.hotel.color : '#2faa61';
            return color;
        },

        footerColor() {
            let color = (this.hotel && this.hotel.footer_color && this.hotel.footer_color.length == 7) ? this.hotel.footer_color : '#050033';
            return color;
        },

        showConfirmationCheckBox() {
            return process.env.VUE_APP_SERVER !== "RU" || this.hotelCode == "novotelcity-test" || this.hotelCode == 'alphasirius';
        },

        setPropertyToLocalStorage(property_id, property_value) {
            var saveData = [{id: parseInt(property_id), value: property_value}];
            if (localStorage.getItem('property_values') !== undefined && localStorage.getItem('property_values') != null) {
                let data = JSON.parse(localStorage.getItem('property_values'));
                if (data.filter(dataItem => parseInt(dataItem.id) === parseInt(property_id)).length > 0) {
                    saveData = data.map(dataItem => {
                        return (parseInt(dataItem.id) === parseInt(property_id)) ? {
                            id: parseInt(property_id),
                            value: property_value
                        } : dataItem;
                    });
                    localStorage.setItem('property_values', JSON.stringify(saveData));
                }
                else
                {
                    data.push({id: parseInt(property_id), value: property_value});
                    localStorage.setItem('property_values', JSON.stringify(data));
                }
            }
            else
            {
                localStorage.setItem('property_values', JSON.stringify(saveData));
            }
        },

        isAllowToUseCookie() {
            return localStorage.getItem('access_cookie') !== undefined && localStorage.getItem('access_cookie') === 'true';
        },

        isMobile: function() {
            let check = false;
            (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))
                check = true;
            })
            (navigator.userAgent||navigator.vendor||window.opera);
            return check;
        },
    }

}).$mount('#app');