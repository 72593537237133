<template>
<modal name="modal-chat" :max-width="600" :adaptive="true" :height="'100%'" id="chat_container" @opened="opened" @closed="closed">
    <div class="bg-white h-full flex flex-col" id="chat_wrapper" style="transition: all 4s ease-in;">
        <div class="chat__header shrink-0">
            <div class="flex gap-3 items-center px-4 border-b h-14">
                <div class="flex gap-2 items-center w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-700 w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                    </svg>
                    <div class="text-lg text-gray-700 truncate pr-4 flex items-center">
                        <div v-if="$root.hotel.site == 'marriottpolyana.2roomz.com'" class="text-sm pt-1">{{ $root.getLangText({'ru': 'Чат работает в', 'en': 'Chat is working in'}) }} <span class="text-red-700 font-medium">{{ $root.getLangText({'ru': 'тестовом режиме', 'en': 'test mode'}) }}</span></div>
                    </div>
                </div>
                <select-language v-if="displaySelectLanguage" class="mx-4"></select-language>
                <div class="flex items-center space-x-4 w-full justify-end" v-else>
                    <button v-if="chat_uuid" class="h-8 text-black focus:outline-none rounded-full  text-center flex space-x-1 items-center bg-stone-100 hover:bg-stone-200 px-2" @click="closeChat();">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-current">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                        </svg>
                    </button>

                    <a href="javascript:void(0)" class="w-8 h-8 text-black bg-white focus:outline-none rounded-full  text-center inline-flex items-center shadow-md hover:shadow" @click="$modal.hide('modal-chat')">
                        <svg width="48" height="48" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.2427 12.7574L12.7574 21.2426M12.7574 12.7574L21.2427 21.2426L12.7574 12.7574Z" stroke="#333333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="chat__content flex flex-col grow h-px overflow-hidden">
            <div class="flex flex-col grow overflow-y-auto" id="chat-grow-wrap">
                <div class="flex flex-col gap-2 px-2" v-if="chat_uuid">
                    <div class="chat_space w-full p-1"></div>
                    <div v-for="message in messages">
                        <div class="flex gap-1" :class="[message.initiator_id === chat.initiator_id ? 'justify-end' : '']">
                            <div class="flex items-end" :class="[message.initiator_id === chat.initiator_id ? 'order-1' : 'order-2']">
                                <div class="flex flex-col text-xs max-w-xs mx-2 order-1 " :class="[message.initiator_id === chat.initiator_id ? 'items-end' : 'items-start']">
                                    <div>                                                                     
                                        <div v-if="message.content == '*file*'">
                                            <a :href="message.extra !== null && $root.getImage(message.extra.file)" target="_blank" class="py-1.5 px-2 text-sm flex font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"></path></svg>
                                                <span class="ml-1 text-xs">File</span>
                                            </a>
                                        </div>
                                        <div v-else class="px-4 py-2 rounded-lg inline-block" :class="[message.initiator_id === chat.initiator_id ? 'rounded-br-none bg-blue-600 text-white' : 'rounded-bl-none bg-[#f1f6f8] border text-black']">
                                            <div v-if="message.extra && message.extra.pictures !== undefined" class="flex flex-wrap gap-2 mb-2 chat_message__pictures">
                                                <img class="rounded" width="320" v-if="message.extra.pictures.length > 0" v-lazy="getLasyImage($root.getImage(message.extra.pictures[0].url))"/>
                                                <!--<hooper v-if="message.extra.pictures.length > 0" :infiniteScroll="true">
                                                    <slide v-for="picture in message.extra.pictures" :key="picture.id">
                                                        <img class="rounded" v-lazy="getLasyImage($root.getImage(picture.url))"/>
                                                    </slide>
                                                    <hooper-pagination slot="hooper-addons" v-if="message.extra.pictures.length > 1"></hooper-pagination>
                                                </hooper>-->
                                            </div>                                                   
                                            <div v-html="displayMessage(message.content)"></div>
                                        </div>
                                        <div v-if="message.extra && message.extra.buttons !== undefined" class="flex flex-wrap gap-2 mt-2">
                                            <div v-for="item in message.extra.buttons">
                                                <button @click="clickFlowButton(item)" class="border px-4 py-2 rounded-xl block hover:underline text-left" :style="{color: $root.hotel.color, 'border-color': $root.hotel.color}">{{ item.title }}</button>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div class="text-[10px] w-full text-gray-300"  :class="[message.initiator_id === chat.initiator_id ? 'text-right' : 'text-left']">{{ message.initiator.name }} - {{ message.created_at|timeFormat }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img v-if="is_typing" v-bind:src="require(`@/assets/image/typing.gif`)" alt="" class="object-contain top-0.5 w-8 h-8"/>
                    <div id="bottom-chat">
                    </div>
                </div>
                <div v-else>
                    <chat-registration @startChat="startChat"></chat-registration>
                </div>
            </div>
            <div class="shrink-0">        
                <input-message
                    v-if="chat && chat_uuid"
                    :chat="chat"
                    :content.sync="content" 
                    :loading.sync="loading_send_message"  
                    @send-message="sendMessage"
                    @send-file-message="sendFileMessage"></input-message>  
            </div>
        </div>
        <v-dialog />
    </div>  
</modal>
</template>

<script>
import { eventBus } from '../../main';
import inputMessage from './input.message.vue';
import selectLanguage from '../layout/header/ArticleSelectLang.vue';
import chatRegistration from './registration.vue';
import { ref, query, limitToLast, onChildAdded } from 'firebase/database';
import { db } from '../../firebase'; // Adjust the path as needed

import { Hooper, Slide, Pagination as HooperPagination } from 'hooper'
import 'hooper/dist/hooper.css';

export default {
    components: { inputMessage, Hooper, Slide, HooperPagination, selectLanguage, chatRegistration },

    data() {
        return {
            // init variable
            chat_uuid: (localStorage.getItem('chat-uuid') != undefined) ? localStorage.getItem('chat-uuid') : '',
            person_uuid: (localStorage.getItem('person-uuid') != undefined) ? localStorage.getItem('person-uuid') : '',
            is_hotel_guest: true,

            // dialog chat
            messages: null,
            chat: null,
            content: '',
            is_typing: false,
            loading_send_message: false,

            // for popup
            visible: false,
            handelDrawer: null,

            // start chat
            name: '',
            room_number: '',

            pusher: null,

            isInitialized: false,
            subscribedChatUuid: null,
        }
    },

    computed: {
        displaySelectLanguage() {
            return window.location.pathname.indexOf('chat') > -1;
        }
    },
    
    created() {
        eventBus.$on('run-chat', data => {
            this.fetchChat();
        });

        eventBus.$on('open-chat', data => {
            this.$modal.show('modal-chat');

            this.fetch();
            this.markAsRead();
        });

        eventBus.$on('change-language', data => {
            this.restart();
        });       
    },

    destroyed() {
        window.removeEventListener("resize", this.resizeChatContainers);
    },
    

    mounted() {
        this.subscribeFirebase();
        //this.subscribeWebsocket();         
    },

    methods: {
        opened() {            
            if (document.querySelector('#chat-messages'))
                document.querySelector('#chat-messages').style.height =  Math.round(document.documentElement.clientHeight - 200) + 'px'; 

            if (document.querySelector('#chat-wrapper')) {                
                document.querySelector('#chat-wrapper').style.width =  Math.round(document.documentElement.width - 150) + 'px';            
            }

            this.$root.freezeActivePageScroll(true);
            document.querySelector('html').classList.add('fixed');
            document.querySelector('body').classList.add('fixed');

            window.addEventListener("resize", this.resizeChatContainers); 

            eventBus.$emit('badges-bottom-navigation',{
                action: 'chat',
                count: 0,
            });            
        },

        closed() {
            this.$root.freezeActivePageScroll(false);
            document.querySelector('html').classList.remove('fixed');
            document.querySelector('body').classList.remove('fixed');

            window.removeEventListener("resize", this.resizeChatContainers);
        },

        async fetch() {
            await this.fetchChat();
            await this.fetchMessages();
        },

        async fetchChat() {
            if (this.chat_uuid === '')
                return;

            var url = process.env.VUE_APP_API_URL + 'public/chat/' + this.chat_uuid;

            try {
                let response = await this.$http.get(url, {params: { person_uuid: this.person_uuid }});
                var results = response.data.data;
                this.chat = results; 
                
                //this.subscribeWebsocket();
                this.subscribeFirebase();
            } catch (e) {
                localStorage.setItem('chat-uuid', '');
                localStorage.setItem('person-uuid', '');
                this.chat_uuid = '';
                this.person_uuid = '';
            }
        },

        async fetchMessages() {
            if (this.chat_uuid === '')
                return;

            var last_message_id = localStorage.getItem('chat-last-message-id') !== undefined ? localStorage.getItem('chat-last-message-id') : null;

            var url = process.env.VUE_APP_API_URL + 'public/chat/' + this.chat_uuid + '/messages?last-message-id=' + last_message_id;

            let response = await this.$http.get(url);
            var results = response.data.data;
            this.messages = results;            

            setTimeout(() => {
                document.getElementById("bottom-chat").scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 300);
        },

        async fetchSingle(id) {
            var url = process.env.VUE_APP_API_URL + 'public/chat/' + this.chat_uuid + '/messages/' + id;

            let response = await this.$http.get(url);
            var result = response.data.data;
            var has_new_message = false;
            if (this.messages !== null && this.messages.length > 0) {
                if (this.messages.filter(item => item.id == result.id).length == 0) {
                    has_new_message = true;
                }
            } else {
                has_new_message = true;
            }            
            
            this.is_typing = false;

            if (has_new_message) {
                this.messages.push(result);
                setTimeout(() => {
                    document.getElementById("bottom-chat").scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                }, 300);
            }
        },

        async startChat(chat_uuid, person_uuid) {
            this.chat_uuid = chat_uuid;
            this.person_uuid = person_uuid;

            this.$nextTick(() => {
                this.fetch();       
            });
        },        

        closeChat() {
            var result = this.$modal.show('dialog', {
                //title: 'The standard Lorem Ipsum passage',
                text: this.$root.getKeyWord('leave_chat'),
                buttons: [
                    {
                    title: this.$root.getKeyWord('no'),
                        handler: () => {
                            this.$modal.hide('dialog')
                        }
                    },
                    {
                    title: this.$root.getKeyWord('yes'),
                        handler: () => {                            
                            this.chat_uuid = null;
                            if (this.messages && this.messages.length > 0)
                                localStorage.setItem('chat-last-message-id', this.messages[this.messages.length - 1].id);
                        
                            localStorage.removeItem('chat-uuid');

                            this.$modal.hide('dialog');
                        }
                    }
                ]
            });
        },

        async restart() {            
            if (!this.chat)
                return;
            
            var url = process.env.VUE_APP_API_URL + 'public/chat/' + this.chat.uuid + '/restart';

            /** TODO: hotel id need to put **/
            await this.$http.post(url, {
                lang: this.$root.currentLanguage,
            });
        },                

        async sendMessage() {
            if (this.content.length == 0) {
                return false;
            }
            
            this.loading_send_message = true;

            var url = process.env.VUE_APP_API_URL + "public/chat/" + this.chat.uuid + '/messages';
            let response = await this.$http.post(url, {
                content: this.content,
                person_uuid: this.person_uuid,
                lang: this.$root.currentLanguage,
            });
                    
            var result = response.data.data;

            await this.fetchSingle(result.id);

            //this.messages.push(results);
            this.content = '';
            this.loading_send_message = false;

            /*setTimeout(() => {
                document.getElementById("bottom-chat").scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 200);*/
        },

        async sendCommandMessage(message, command) {
            this.loading_send_message = true;

            var url = process.env.VUE_APP_API_URL + "public/chat/" + this.chat.uuid + '/messages';
            let response = await this.$http.post(url, {
                content: message,
                command: command,
                person_uuid: this.person_uuid,
                lang: this.$root.currentLanguage,
            });
            
            var results = response.data.data;

            this.messages.push(results);
            this.content = '';

            this.loading_send_message = false;

            setTimeout(() => {
                document.getElementById("bottom-chat").scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 200);
        },

        async sendFileMessage(file) {
            this.loading_send_message = true;

            var url = process.env.VUE_APP_API_URL + "public/chat/" + this.chat.uuid + '/messages';
            let response = await this.$http.post(url, {
                content: "*file*",
                extra: {file: file},
                person_uuid: this.person_uuid
            });
            
            var results = response.data.data;

            this.messages.push(results);
            this.content = '';

            this.loading_send_message = false;

            console.log('finish upload file');
            console.log(this.loading);

            setTimeout(() => {
                document.getElementById("bottom-chat").scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 200);
        },

        close() {
            this.visible = false;
        },

        displayMessage(text) {
            text = text.replace(/\n/g, '<br />');
            return this.replaceURLWithHTMLLinks(text);
        },

        replaceURLWithHTMLLinks(text) {
            var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            var replace = text.replace(exp,"<a class='text-blue-500' href='$1'>$1</a>");
            return replace; 
        },

        
        /*subscribeWebsocket() {
            if (this.chat == null) 
                return;

            var key = process.env.VUE_APP_PUSHER_KEY;
            var cluster = process.env.VUE_APP_PUSHER_CLUSTER;

            // Initialize Pusher if it doesn't exist
            if (!this.pusher) {
                this.pusher = new Pusher(key, { cluster: cluster });
            }

            const channelName = 'chat-websocket-event.' + this.chat.uuid;

            // Check if the channel is already subscribed
            if (this.pusher.channel(channelName)) {
                console.log(`Already subscribed to channel: ${channelName}`);
                return;
            }

            // Subscribe to the channel
            const channel = this.pusher.subscribe(channelName);

            channel.bind('chatWebsocketEvent', data => {
                if (data.code == 'start-companion-typing') {
                    this.is_typing = true;
                    setTimeout(() => {
                        document.getElementById("bottom-chat").scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }, 200);
                    return;
                }

                if (data.code == 'end-companion-typing') {
                    this.is_typing = false;
                    setTimeout(() => {
                        document.getElementById("bottom-chat").scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }, 200);
                    return;
                }                

                if (data.code == 'chat-message-created') {
                    if (data.data.extra.initiator_id !== this.person_uuid) {
                        try {
                            this.fetchSingle(data.data.chat_message_id);

                            eventBus.$emit('badges-bottom-navigation', {
                                action: 'chat',
                                count: 1,
                            });
                        } catch(e) {
                            console.error(e);
                        }
                    }
                }
            });       
        },*/

        subscribeFirebase() {
            if (this.chat && this.chat.uuid === this.subscribedChatUuid) {
                // Already subscribed to this chat
                console.log('Already subscribed to this chat:', this.chat.uuid);
                return;
            }
            
            const serverPrefix = process.env.VUE_APP_SERVER.toLowerCase();
            const channel = `channels/${serverPrefix}/chat/${this.chat.uuid}/messages`;
            var messageRef = query(ref(db, channel), limitToLast(1));
            
            this.subscribedChatUuid = this.chat.uuid; 
            sessionStorage.setItem(`processedFirstMessage_` + this.chat.uuid, 'false');

            onChildAdded(messageRef, (snapshot) => {

                if (sessionStorage.getItem(`processedFirstMessage_` + this.chat.uuid) !== 'true') {
                    sessionStorage.setItem(`processedFirstMessage_` + this.chat.uuid, 'true');
                    return;
                }

                const data = snapshot.val();
                
                if (data) {
                    if (data.type == 'start-companion-typing') {
                        this.is_typing = true;
                        setTimeout(() => {
                            document.getElementById("bottom-chat").scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }, 200);
                        return;
                    }

                    if (data.type == 'end-companion-typing') {
                        this.is_typing = false;
                        setTimeout(() => {
                            document.getElementById("bottom-chat").scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }, 200);
                        return;
                    }

                    if (data.type == 'chat-message-created') {
                        if (data.extra.initiator_id !== this.person_uuid) {
                            try {
                                console.log('chat message created');
                                this.fetchSingle(data.chat_message_id);

                                eventBus.$emit('badges-bottom-navigation', {
                                    action: 'chat',
                                    count: 1,
                                });
                            } catch (e) {
                                console.error(e);   
                            }
                        }
                    }
                }
            });
        },

        async markAsRead() {
            if (this.chat == null)
                return;

            var url = process.env.VUE_APP_API_URL + "public/chat/" + this.chat.uuid + '/mark-as-read';
            var response = await this.$http.post(url, {person_uuid: this.person_uuid});

            eventBus.$emit('badges-bottom-navigation',{
                action: 'chat',
                count: 0,
            });
        },

        resizeChatContainers() {
            if (document.querySelector('#chat-messages'))
                document.querySelector('#chat-messages').style.height =  Math.round(document.documentElement.clientHeight - 200) + 'px'; 

            if (document.querySelector('#chat-wrapper')) {                
                document.querySelector('#chat-wrapper').style.width =  Math.round(document.documentElement.width - 150) + 'px';            
            }
        },

        clickFlowButton(item) {
            if (item.msg_id && item.msg_id.length > 0) {
                this.sendCommandMessage(item.title, item.msg_id);
            } if (item.link.length > -1) {
                window.open(item.link, '_blank');
            }
        }
    }
}
</script>

<style>
    @supports (-webkit-touch-callout: none) {
        #chat_container .vm--modal {
            border-radius: 0 !important;
        }
    }

    @supports not (-webkit-touch-callout: none) {
        #chat_container .vm--modal {
            border-radius: 0 !important;
            top: 0px !important;
        }
    }    

    #chat_container .vue-dialog {
        border-radius: 13px !important;
    }
    
    .chat_message__pictures .hooper {
        width: 320px !important;
    }

    .chat_message__pictures .hooper-slide {
        width: 320px !important;
    }

    .chat_message__pictures .hooper-slide img {
        width: 320px !important;
    }
    
    @media (min-width: 481px) {
        .chat_message__pictures .hooper {
            width: 420px !important;
        }

        .chat_message__pictures .hooper-slide {
            width: 420px !important;
        }   


        .chat_message__pictures .hooper-slide img {
            width: 420px !important;
        }
    }

    [data-hs-tab].active.hs-tab-active\:shadow-sm {
        --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    [data-hs-tab].active.hs-tab-active\:text-gray-700 {
        --tw-text-opacity: 1;
        color: rgb(55 65 81 / var(--tw-text-opacity));
    }
    [data-hs-tab].active.hs-tab-active\:bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    }    
</style>