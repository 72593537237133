// src/firebase.js
import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database';

// If using Firestore instead: import 'firebase/firestore';

// Replace with your own Firebase config object
const firebaseConfig = {
    apiKey: "AIzaSyCU2-B_q8FQ8RSajB7xOhtr9QPB3qp7fbE",
    authDomain: "liveupdate-9df4d.firebaseapp.com",
    projectId: "liveupdate-9df4d",
    storageBucket: "liveupdate-9df4d.firebasestorage.app",
    messagingSenderId: "777123463003",
    appId: "1:777123463003:web:d4b5c3b18f664df274a47e",
    measurementId: "G-TCGSMKPGM3",
    databaseURL: 'https://liveupdate-9df4d-default-rtdb.europe-west1.firebasedatabase.app'
};

// Initialize Firebase only once
const firebaseApp = initializeApp(firebaseConfig);

// Export the Realtime Database instance
export const db = getDatabase(firebaseApp);
// If using Firestore, for example: export const db = firebaseApp.firestore();
